import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/research/medicine/002.jpg';
// import blogd2 from '../../assets/images/2022/federal-seminar/2.jpg';
// import blogd3 from '../../assets/images/2022/federal-seminar/3.jpg';
// import blogd4 from '../../assets/images/2022/federal-seminar/4.jpg';
// import blogd5 from '../../assets/images/2022/federal-seminar/5.jpg';
// import blogd6 from '../../assets/images/2022/federal-seminar/6.jpg';
// import blogd7 from '../../assets/images/2022/federal-seminar/7.jpg';
// import blogd8 from '../../assets/images/2022/federal-seminar/8.jpg';

import NavFour from '../../components/NavFour';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import { CircleCheck } from 'tabler-icons-react';
import { Spoiler, Table, List, ThemeIcon } from '@mantine/core';

const NewsDetails = () => {
  const services = [
  
    {
      name: "	Be LUTH resident doctors.",
    },
    {
      name: '	Provide a copy of the letter of employment by LUTH.',
    },
    { name: "Provide evidence of passing Part 1 Examination of any of the Postgraduate Medical Colleges. " },
    {
      name: '	Provide evidence of approval of dissertation proposal by any of the Postgraduate Medical Colleges. ',
    },

    {
      name: '	Have at least 18 months Before exit  from Residency Training Programme as at October 1st, 2024.',
    },
    {
      name: '	Provide evidence of attending Research Methodology Training of any of the Postgraduate Medical Colleges.',
    },
    { name: '	Provide evidence of Research Ethics Certification ' },
    {
      name: '	Be willing to publish at least one paper from the study and LUTH sponsorship must be acknowledged in the dissertation and publication.',
    },
    // {
    //   name: 'Finance And Accounts',
    // },
    // {
    //   name: '	General Administration Division',
    // },
    // {
    //   name: '	Hospital Facilities Management',
    // },
    // {
    //   name: '	Human Resources Management Division',
    // },
    // {
    //   name: '	Legal Services Department',
    // },
    // { name: '	Printing Unit' },
    // {
    //   name: 'Procurement Department',
    // },
    // {
    //   name: 'Protocol Unit',
    // },
    // {
    //   name: '	Security Unit',
    // },
    // {
    //   name: '	Servicom Unit',
    // },
    // {
    //   name: '	Staff Development, Training And Welfare',
    // },
    // {
    //   name: '	Stores Department',
    // },
  ];
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>CALL FOR APPLICATIONS</h2>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Definition/Introduction */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Senior Resident Doctors employed by the Lagos University
                  Teaching Hospital are hereby invited to apply for the LUTH
                  Research Grants towards the execution of their Part II
                  projects.
                </p>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div> */}
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Types of DM
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  There are four types of DM. Type 1, Type 2, Hyperglycaemia
                  first detected in Pregnancy and other specific types. Type 2
                  DM is the commonest, consisting about 90% of DM.
                </p> */}
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div> */}
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Common classical symptoms of DM
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Increased thirst, increased urination, excessive hunger &
                  eating, nocturia and weight loss. These symptoms develop
                  rapidly (T1DM) or gradually (T2DM). T2DM may not present with
                  any symptoms but with complications such as high glucose
                  crisis or emergencies, stroke, blindness, kidney failure,
                  heart attack, heart failure, poor erection or impotence,
                  infertility, foot ulcer and peripheral artery diseases.
                </p> */}
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  This Call is from
                </span>
                <p
                  className='blog-one__text'
                  style={{ fontSize: 34, marginBottom: 30, fontStyle: 'bold' }}
                >
                  15th July to 30th September, 2024
                </p>
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div> */}
                {/* <span style={{ fontSize: 14, fontStyle: 'bold' }}>
                  Diagnosis of DM
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                 
                </p>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Management of DM
                </span> */}
                
                <h2 className='blog-one__title' style={{ marginBottom: 30, textAlign: 'left' }}>
                  Eligibility Criteria
                </h2>
                <p
                  className='blog-one__text'
                  style={{ marginBottom: 30, textAlign: 'left' }}
                >
                  Applicants must
                  <List
                    spacing='xs'
                    size='sm'
                    center
                    icon={
                      <ThemeIcon color='teal' size={24} radius='xl'>
                        <CircleCheck size={16} />
                      </ThemeIcon>
                    }
                  >
                    {services.map((data) => (
                      <List.Item key={data.name}>{data.name}</List.Item>
                    ))}
                  </List>
                </p>
                <br />
                <br />
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  For further information and enquires please contact 
                </p>
                <span style={{ fontSize: 25, fontStyle: 'italic' }}>
                  08023050604
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>The Research Grant Committee</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavFour />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
